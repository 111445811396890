<template>
    <div>
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>

<script>
    export default {
        name: 'MembersList',
        data() {
            let _this = this
            return {
                opt: {
                    title: '批量排房',
                    isNotShowSelection: true, //不显示表格列表多选框
                    houseList: [],
                    search: [{
                        key: "date",
                        label: "操作排房日期",
                        type: "data-range",
                    }, {
                        key: "houseName",
                        label: "房源名称",
                        type: "select",
                        opt: {
                            list: [],
                        }
                    }, {
                        key: "userName",
                        label: "操作人",
                        maxlength: 24,
                    }],
                    columns: [{
                            label: "批量排房单号",
                            key: "id"
                        },
                        {
                            label: "排房房源",
                            key: "houseName"
                        },
                        {
                            label: "排房房间（间）",
                            key: "roomCount"
                        },

                        {
                            label: "排房人数（人）",
                            key: "planCount"
                        },
                        {
                            label: "创建租约（条）",
                            key: "leaseCountNum"
                        },
                        {
                            label: "操作人（角色）",
                            key: "optionInfo"
                        },
                        {
                            label: "操作排房时间",
                            key: "createdDate"
                        },
                        {
                            label: "操作排房状态",
                            key: "planStateStr"
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-but",
                            opt: {
                                list: [{
                                    label: "查看日志",
                                    on(row) {
                                        _this.checkLog(row)
                                    }
                                }]
                            }
                        },
                    ],
                    buttons: [{
                            type: 0,
                            name: "批量排房",
                            on() {
                                _this.$router.push("/main/batchrRangement/detail")

                            }
                        },
                    ]
                }
            }
        },
        activated() {
            this.getApartmentList();
        },
        created() {
             //房东 或 有排房操作权限的房管员 才显示排房按钮
            if(!this.getLandlordFlage() && !this.getHousekeeperPlanAndQuitFlage()) {
                console.log('不是房东、没有排房权限的房管员')
                this.opt.buttons = [];
            }
        },

        methods: {
            onGet(opt) {
                let dto = {
                    current: opt.skip,
                    pageSize: opt.limit,
                    startDate: opt.searchForm['date'] ? opt.searchForm['date'][0] : null,
                    endDate: opt.searchForm['date'] ? opt.searchForm['date'][1] : null,
                    ...opt.searchForm
                }
                if (dto.date) {
                    delete dto.date
                }
                if(opt.searchForm.houseName) {
                    this.houseList.forEach(item => {
                        if(opt.searchForm.houseName == item.value) {
                            dto.houseName = item.label
                        }
                    })
                } 
                this.post('tenant-service/lease/v1/batch-plan/info/page', dto, {
                    isUseResponse: true,
                }).then(res => {
                    if (res.data.code == 0 && res.data.data.data.length > 0) {
                        res.data.data.data.forEach(item => {
                            
                            if(item.planState == 2) {
                                item.planStateStr ='已完成' + item.planSuccessCount + ',失败' + item.planFailCount;
                            }else {
                                item.planStateStr = ["准备中","处理中"][item.planState];
                            }
                            if(item.planState != 2) {
                                item.roomCount = "--";
                                item.planCount = "--";
                                item.leaseCountNum = "--";
                                
                            }else {
                                item.leaseCountNum = item.leaseCount ? item.leaseCount : 0;
                            }
                           
                            if(item.roleName) {
                                item.optionInfo = item.userName + '(' + item.roleName + ')';
                            }else {
                                item.optionInfo = item.userName;
                            }
                        })
                    }
                    opt.cb(res.data.data)
                })
            },
            //获取房源列表
            getApartmentList() {
                var dto = {
                    pageNumber: "1",
                    pageSize: "99999",
                    landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
                        null : this.cache.getLS("userInfo")["userId"]
                };
                return new Promise((resolve, reject) => {
                    this.post("landlord-service/apartment/queryPagesApartment", dto).then(
                        res => {
                            let returnData = {};
                            res.forEach(data => {
                                returnData[data.id] = data.name;
                            });
                            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                            this.opt.search.forEach(item => {
                                if (item.key == "houseName") {
                                    item.opt.list = res;
                                }
                            })
                            this.houseList = res;
                            resolve(returnData);
                        }
                    );
                });
            },
            checkLog(item) {
                // if(item.planState != 2) {
                //     return this.$message({
                //         message: '处理中，请稍后查看日志。',
                //         type: 'warning'
                //     })
                // }
                this.$router.push({
                    path: '/main/batchrrangement/rowHouseLog',
                    query: {
                        id: item.id,
                        leaseCount: item.leaseCount ? item.leaseCount : 0,
                        planFailCount: item.planFailCount ? item.planFailCount : 0,
                        planSuccessCount: item.planSuccessCount ? item.planSuccessCount : 0,
                        createdDate: item.createdDate ? item.createdDate : '--',
                        planState: item.planState,
                    }
                })
            },

        }
    }
</script>

<style lang="scss" scoped>

</style>